<template>
  <div class="loader">
    <div class="d-flex justify-content-center">
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<style scoped>
html {
  height: 100%;
}

body {
  background-image: radial-gradient(circle farthest-corner at center, #3C4B57 0%, #1C262B 100%);
}

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}
</style>

<template>
  <div class="iframe-container" v-html="iframe"></div>
</template>

<script>
export default {
  name: 'MobilePlayer',
  props: ['pauseVideoURL'],
  computed: {
    iframe() {
      const videoUrl = this.pauseVideoURL.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/
      );
      if (videoUrl[3] === 'youtube.com') {
        const pattern =
          /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;
        if (pattern.test(this.pauseVideoURL)) {
          const videoID = this.pauseVideoURL.split("=").pop();
          const replacement =
            `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/$1?modestbranding=1&controls=0&autoplay=1&mute=1&loop=1&playlist=${videoID}" frameborder="0" allowfullscreen class="responsive-iframe"></iframe>`;

          let iframe = this.pauseVideoURL
            .replace(pattern, replacement)
            .replace('&amp;t=', '?t=');
          console.log(iframe);
          return iframe;
        }
      } else if (videoUrl[3] === 'vimeo.com') {
        const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
        if (pattern.test(this.pauseVideoURL)) {
          const replacement =
            '<iframe width="100%" height="100%" src="https://player.vimeo.com/video/$1?loop=1&autoplay=1&mute=1&autopause=0&background=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="responsive-iframe"></iframe>';
          return this.pauseVideoURL.replace(pattern, replacement);
        }
      }
    },
  },
  data() {
    return {};
  },
  created() {
    console.log(this.pauseVideoURL);
  },
};
</script>
<style scoped>
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>

<template>
  <Loader v-if="loading" />
  <div v-else>
    <InputDialog
      v-if="passwordEntry === ''"
      title="Login"
      placeholder="Password"
      inputType="password"
      :submitAction="setPasswordEntry"
      :errorMessage="loginErrorMessage"
    />
    <InputDialog
      v-if="passwordEntry !== ''"
      title="What's your name?"
      placeholder="Name"
      :submitAction="login"
      inputType="text"
      :termsAgreement="termsAgreementContent"
    />
  </div>
</template>

<script>
import axios from 'axios';
import InputDialog from '../components/InputDialog.vue';
import Loader from '../components/Loader.vue';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'Login',
  components: {
    InputDialog,
    Loader,
  },
  data() {
    return {
      loading: true,
      nameEntry: '',
      passwordEntry: '',
      loginErrorMessage: '',
      termsAgreementContent: `
        <p class="terms-agreement">
          By logging in, you agree to our <a 
            href="https://pixelview.io/privacy-policy"
            target="_blank"
            class="terms-link"
          >Privacy Policy</a> and <a
            href="https://www.pixelview.io/terms-of-service"
            target="_blank"
            class="terms-link"
          >Terms of Service</a>.
        </p>
      `,
    };
  },
  async created() {
    if (
      localStorage.getItem('kicked_from_session') ===
      this.$route.params.sessionID
    ) {
      this.loginErrorMessage = 'The stream has ended. Thanks for watching!';
      this.passwordEntry = '';
      this.loading = false;
      return;
    }

    // Token login
    if (this.$route.query.token) {
      try {
        this.passwordEntry = atob(this.$route.query.token);
        this.nameEntry = localStorage.getItem('userName');
        if (
          this.nameEntry &&
          localStorage.getItem('sessionID') === this.$route.params.sessionID
        ) {
          await this.login(this.nameEntry);
        }
      } catch {
        this.$router.push({
          name: 'Login',
          params: { sessionID: this.$route.params.sessionID },
        });
      }
    }
    this.loading = false;
  },
  methods: {
    setPasswordEntry(password) {
      this.passwordEntry = password;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async login(name) {
      this.loading = true;
      this.loginErrorMessage = '';

      const sessionID = this.$route.params.sessionID;
      const password = this.passwordEntry;
      name = this.capitalizeFirstLetter(name);

      // Check if we have a twilio UID in storage
      // to reuse user if session is the same
      var savedTwilioUserID = localStorage.getItem('twilioUserID');
      if (savedTwilioUserID === null) {
        savedTwilioUserID = '';
      } else {
        var UIDSession = savedTwilioUserID.split('-')[0];
        if (sessionID !== UIDSession) {
          console.log('New session, resetting UID');
          savedTwilioUserID = '';
          localStorage.removeItem('twilioUserID');
        } else {
          console.log('Existing chat session');
        }
      }

      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      console.log('Is Safari', isSafari);
      try {
        const res = await axios({
          headers: {
            'Content-Type': 'application/json',
          },
          url: process.env.VUE_APP_API + '/login/player',
          method: 'post',
          data: {
            session_id: sessionID,
            password,
            name,
            twilio_user_id: savedTwilioUserID,
            platform: 'WEB',
            browser: isSafari ? 'SAFARI' : 'OTHER',
            mobile: isMobile,
          },
        });
        if (res.status === 200) {
          this.$store.commit('setSessionID', sessionID);
          this.$store.commit('setStreamUrl', res.data.stream_url);
          this.$store.commit('setPlayer', res.data.player);
          console.log('Player', res.data.player);
          console.log('Stream URL', res.data.stream_url);
          this.$store.commit('setUserName', name);
          this.$store.commit('setClientToken', res.data.client_token);
          this.$store.commit('setVideoChatToken', res.data.video_chat_token);
          this.$store.commit('setTwilioUserID', res.data.twilio_user_id);
          localStorage.setItem('twilioUserID', res.data.twilio_user_id);
          this.$store.commit('setTwilioToken', res.data.twilio_token);

          console.log('Twilio user ID', res.data.twilio_user_id.slice(-5));

          this.$store.commit('setLogoImageURL', res.data.logo_image);
          this.$store.commit(
            'setSessionDescription',
            res.data.session_description
          );
          this.$store.commit('setShowVideoChat', res.data.show_video_chat);
          this.$store.commit('setShowTextChat', res.data.show_text_chat);
          this.$store.commit(
            'setTextChatHiddenByAdmin',
            !res.data.show_text_chat
          );
          this.$store.commit('setPauseVideoURL', res.data.pause_video_url);
          this.$store.commit('setPaused', res.data.paused);

          // If paused on load and there is a pause video URL
          // show it instead of skeleton
          if (res.data.paused && res.data.pause_video_url) {
            this.$store.commit('setLoadingVideo', false);
          }

          this.$store.commit('setPauseMessage', res.data.pause_message);
          this.$store.commit('setName', name);

          console.log('Sending add viewer');
          console.log('Token', res.data.client_token);
          this.$socket.sendObj({
            action: 'sendMessage',
            message_type: 'CLIENT_REQUEST',
            message: 'ADD_VIEWER',
            data: {
              client_token: res.data.client_token,
              name,
            },
          });

          localStorage.setItem('userName', name);
          localStorage.setItem('sessionID', sessionID);

          let token = '';
          if (this.$route.query.token === undefined) {
            token = btoa(password);
          } else {
            token = this.$route.query.token;
          }
          this.$router.push({
            name: 'Home',
            params: { sessionID: this.$route.params.sessionID },
            query: { token: token },
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.data.error === 'Unknown session ID') {
          this.loginErrorMessage = 'Unknown session ID. Please try again.';
        } else if (error.response.data.error === 'Wrong password') {
          this.loginErrorMessage = 'Wrong password. Please try again.';
        } else if (error.response.data.error === 'Viewers limit reached') {
          this.loginErrorMessage =
            'Maximum viewers limit reached. Contact your host for more information.';
        } else if (error.response.data.error === 'Session archived') {
          this.loginErrorMessage = 'The stream has ended. Thanks for watching!';
        } else {
          console.log(error.response);
          console.log('Unknown error');
          this.unknownSession = true;
        }
      } finally {
        this.passwordEntry = '';
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.terms-agreement {
  font-size: 12px;
  color: #6c757d;
  margin-top: 15px;
}

.terms-agreement .terms-link {
  color: #007bff !important;
  text-decoration: none !important;
}

.terms-agreement .terms-link:hover {
  text-decoration: underline !important;
}
</style>

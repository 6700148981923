<template>
  <div :style="{ backgroundColor: backgroundColor }">
    <div class="container-fluid">
      <div style="text-align: center">
        <Loader v-if="loading" />
        <div v-else class="container">
          <div class="text-start px-2 py-3">
            <img
              :src="logoImageURL ? logoImageURL : pixelviewLogo"
              class="logo-image"
            />
          </div>
          <div class="media-container row margin-top">
            <div
              id="video-player-section"
              class="position-relative padding-80 w-100 h-100"
            >
              <div v-if="paused">
                <div class="video-player" id="video-player">
                  <div class="row position-relative">
                    <span class="pause-message-text">
                      {{ pauseMessage }}
                    </span>
                    <pause-player
                      v-if="pauseVideoURL"
                      :pauseVideoURL="pauseVideoURL"
                    />
                  </div>
                </div>
              </div>

              <div v-else>
                <p v-if="sessionDescription" class="reduced-opacity">
                  {{ sessionDescription }}
                </p>
                <div class="video-player" id="video-player">
                  <div>
                    <VideoChat v-if="showVideoChat" />
                    <div class="d-flex column-gap-lg-4">
                      <video-player-skeleton
                        v-if="loadingVideo"
                        :showTextChat="showTextChat"
                        :message="
                          paused ? formattedPauseMessage : loadingVideoMessage
                        "
                      />

                      <div
                        v-show="!loadingVideo"
                        v-if="screenShare === null"
                        class="w-100 position-relative overflow-hidden"
                        id="player-container"
                      >
                        <vue-drawing-canvas
                          v-if="draw"
                          ref="VueCanvasDrawing1"
                          :key="canvasKey"
                          v-model:image="image"
                          :width="playerWidth"
                          :height="playerHeight"
                          :stroke-type="strokeType"
                          :line-cap="lineCap"
                          :line-join="lineJoin"
                          :fill-shape="fillShape"
                          :eraser="eraser"
                          :lineWidth="line"
                          :color="color"
                          :background-color="'transparent'"
                          :background-image="backgroundImage"
                          :watermark="watermark"
                          :initial-image="strokes"
                          saveAs="png"
                          :styles="{
                            border: 'solid 0px #fff',
                          }"
                          :lock="disabled"
                          :additional-images="additionalImages"
                          @mouseup="sendStrokes"
                          @touchend="sendStrokes"
                        />

                        <template
                          v-if="player === 'WHEP'"
                          class="media-container__template"
                        >
                          <web-rtc-player
                            v-if="streamStatus == 'online'"
                            @handle-resize="handleResize"
                            @loading-video="setLoadingVideo"
                            :streamUrl="streamUrl"
                          />
                        </template>

                        <template v-else>
                          <main-player
                            v-if="streamStatus == 'online'"
                            @handle-resize="handleResize"
                            @loading-video="setLoadingVideo"
                            @error-message="setPlayerErrorMessage"
                            :streamUrl="streamUrl"
                          />
                        </template>
                      </div>

                      <div v-if="screenShare">
                        <div class="video-share-section"></div>
                        <div id="video-share">
                          <div>
                            <vue-drawing-canvas
                              v-if="draw"
                              ref="VueCanvasDrawing2"
                              :key="canvasKey"
                              v-model:image="image"
                              :width="playerWidth"
                              :height="playerHeight"
                              :stroke-type="strokeType"
                              :line-cap="lineCap"
                              :line-join="lineJoin"
                              :fill-shape="fillShape"
                              :eraser="eraser"
                              :lineWidth="line"
                              :color="color"
                              :background-color="'transparent'"
                              :background-image="backgroundImage"
                              :watermark="watermark"
                              :initial-image="strokes"
                              saveAs="png"
                              :styles="{
                                border: 'solid 0px #fff',
                              }"
                              :lock="disabled"
                              :additional-images="additionalImages"
                              @mouseup="sendStrokes"
                              @touchend="sendStrokes"
                            />
                            <screenshare-tile :participant="screenShare" />
                          </div>
                        </div>
                      </div>
                      <Chat
                        v-if="!textChatHiddenByAdmin"
                        class="chat-section"
                        :class="showTextChat ? '' : 'hide-chat-wrapper'"
                        :token="twilioToken"
                        :userID="twilioUserID"
                        @handle-resize="handleResize"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <toolbar
                    @clicked-undo-draw-stroke="undoDrawStroke"
                    @handle-resize="handleResize"
                  ></toolbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import MainPlayer from '../components/MainPlayer.vue';
import Message from '../components/Message.vue';
import Loader from '../components/Loader.vue';
import StreamInfo from '../components/StreamInfo.vue';
import VideoChat from '../components/videochat/VideoChat.vue';
import VueDrawingCanvas from 'vue-drawing-canvas';
import pixelviewLogo from '../assets/logo.png';
import ScreenshareTile from '../components/videochat/ScreenshareTile.vue';
import Toolbar from '../components/Toolbar';
import VideoPlayerSkeleton from '../components/VideoPlayerSkeleton';
import PausePlayer from '../components/PausePlayer.vue';
import Chat from '../components/textchat/Chat.vue';
import WebRtcPlayer from '../components/WebRtcPlayer.vue';

export default {
  name: 'Home',
  components: {
    WebRtcPlayer,
    VideoPlayerSkeleton,
    Toolbar,
    MainPlayer,
    Message,
    StreamInfo,
    Loader,
    VideoChat,
    VueDrawingCanvas,
    ScreenshareTile,
    PausePlayer,
    Chat,
  },
  data() {
    return {
      pixelviewLogo,
      checkStreamStatus: null,
      getStreamStatusTries: 0,
      loading: true,
      convertedVideo: '',
      window: {
        width: 0,
      },
      image: '',
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 5,
      strokeType: 'dash',
      lineCap: 'square',
      lineJoin: 'miter',
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
      resizeTimeout: null,
      requestedInitalStrokes: false,
      loadingVideoMessage: 'Loading stream',
    };
  },
  mounted() {
    this.checkStreamStatus = setInterval(() => {
      this.getStreamStatus();
    }, 1500);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.colorOptions = this.getDistinctColors();
    this.color = this.colorOptions[0];
    this.strokes = this.defaultStrokes;
    this.myStrokes = this.defaultStrokes;
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    formattedPauseMessage() {
      if (this.pauseMessage !== ' ') {
        return this.pauseMessage;
      }
      return 'Stream paused';
    },
    loadingVideo: {
      get() {
        return this.$store.state.loadingVideo;
      },
      set(value) {
        this.$store.commit('setLoadingVideo', value);
      },
    },
    defaultStrokes() {
      // Add some default strokes otherwise it crashes
      return Array.from({ length: this.colorOptions.length }, (_, i) => ({
        type: 'dash',
        from: {
          x: 0,
          y: 0,
        },
        coordinates: [],
        color: this.colorOptions[i],
        width: 5,
        fill: false,
      }));
    },
    draw: {
      get() {
        return this.$store.state.draw;
      },
      set(value) {
        this.$store.commit('setDraw', value);
      },
    },
    myStrokes: {
      get() {
        return this.$store.state.myStrokes;
      },
      set(value) {
        this.$store.commit('setMyStrokes', value);
      },
    },
    playerWidth: {
      get() {
        return this.$store.state.playerWidth;
      },
      set(value) {
        this.$store.commit('setPlayerWidth', value);
      },
    },
    playerHeight: {
      get() {
        return this.$store.state.playerHeight;
      },
      set(value) {
        this.$store.commit('setPlayerHeight', value);
      },
    },
    canvasKey: {
      get() {
        return this.$store.state.canvasKey;
      },
      set(value) {
        this.$store.commit('setCanvasKey', value);
      },
    },
    colorOptions: {
      get() {
        return this.$store.state.colorOptions;
      },
      set(value) {
        this.$store.commit('setColorOptions', value);
      },
    },
    strokes: {
      get() {
        return this.$store.state.strokes;
      },
      set(value) {
        this.$store.commit('setStrokes', value);
      },
    },
    streamStatus: {
      get() {
        return this.$store.state.streamStatus;
      },
      set(value) {
        this.$store.commit('setStreamStatus', value);
      },
    },
    paused: {
      get() {
        return this.$store.state.paused;
      },
      set(value) {
        this.$store.commit('setPaused', value);
      },
    },
    pauseMessage: {
      get() {
        return this.$store.state.pauseMessage;
      },
      set(value) {
        this.$store.commit('setPauseMessage', value);
      },
    },
    color: {
      get() {
        return this.$store.state.color;
      },
      set(value) {
        this.$store.commit('setColor', value);
      },
    },
    backgroundColor: {
      get() {
        return this.$store.state.backgroundColor;
      },
      set(value) {
        this.$store.commit('setBackgroundColor', value);
        // Remove this line to prevent changing the body background color
        // document.body.style.backgroundColor = value;
      },
    },
    ...mapGetters([
      'sessionID',
      'showVideoChat',
      'showTextChat',
      'inVideoCall',
      'logoImageURL',
      'paused',
      'pauseMessage',
      'pauseVideoURL',
      'clientToken',
      'screenShare',
      'textChatHiddenByAdmin',
      'sessionDescription',
      'twilioToken',
      'twilioUserID',
      'player',
      'streamUrl',
    ]),
  },
  methods: {
    getAllStrokes() {
      let canvas1Strokes = [];
      let canvas2Strokes = [];

      if (this.$refs.VueCanvasDrawing1) {
        canvas1Strokes = this.$refs.VueCanvasDrawing1.getAllStrokes();
      }
      if (this.$refs.VueCanvasDrawing2) {
        canvas2Strokes = this.$refs.VueCanvasDrawing2.getAllStrokes();
      }
      return canvas1Strokes.concat(canvas2Strokes);
    },
    sendStrokes() {
      console.log('sendStrokes');
      this.myStrokes = this.getAllStrokes().filter((stroke) =>
        this.colorOptions.includes(stroke.color)
      );
      this.$socket.sendObj({
        action: 'sendMessage',
        message_type: 'CLIENT_REQUEST',
        message: 'NEW_BRUSH_STROKES',
        data: {
          client_token: this.clientToken,
          strokes: {
            player_width: this.playerWidth,
            player_height: this.playerHeight,
            strokes: this.myStrokes,
          },
        },
      });
    },
    hexToRgb(hex) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return [r, g, b];
    },
    colorDistance(color1, color2) {
      const [r1, g1, b1] = this.hexToRgb(color1);
      const [r2, g2, b2] = this.hexToRgb(color2);
      return Math.sqrt((r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2);
    },
    isColorTooSimilar(newColor, colorArray) {
      const threshold = 100; // Adjust as needed
      return colorArray.some(
        (color) => this.colorDistance(newColor, color) < threshold
      );
    },
    getBrightness(hexColor) {
      const r = parseInt(hexColor.substr(1, 2), 16);
      const g = parseInt(hexColor.substr(3, 2), 16);
      const b = parseInt(hexColor.substr(5, 2), 16);
      return (r * 299 + g * 587 + b * 114) / 1000;
    },
    getDistinctColors() {
      let colors = [];
      while (colors.length < 3) {
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += Math.floor(Math.random() * 16).toString(16);
        }

        if (
          this.getBrightness(color) < 150 ||
          this.getBrightness(color) > 255 ||
          this.isColorTooSimilar(color, colors)
        ) {
          continue;
        }

        colors.push(color);
      }

      return colors;
    },
    handleResize() {
      console.log('handleResize');
      // Setting timeout to not redraw strokes too often
      // crashing it
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        const oldWidth = this.playerWidth;
        const oldHeight = this.playerHeight;

        this.window.width = window.innerWidth;
        if (document.getElementById('player-container')) {
          this.playerHeight =
            document.getElementById('player-container').clientHeight;
          this.playerWidth =
            document.getElementById('player-container').clientWidth;

          // Request strokes from other users on first load
          // when the document is ready
          if (!this.requestedInitalStrokes) {
            setTimeout(() => {
              this.requestInitalStrokes();
            }, 10000);
            this.requestedInitalStrokes = true;
          }
        } else {
          this.playerWidth = 1900;
          this.playerHeight = 1000;
          setTimeout(() => {
            this.handleResize();
          }, 5000);
        }

        // Scale strokes and redraw them on screen
        try {
          if (this.draw) {
            const reSizedStrokes = this.scaleStrokes(
              this.getAllStrokes(),
              oldWidth,
              oldHeight,
              this.playerWidth,
              this.playerHeight
            );
            this.strokes = reSizedStrokes;
            this.myStrokes = reSizedStrokes.filter((stroke) =>
              this.colorOptions.includes(stroke.color)
            );
            this.canvasKey++;
          }
        } catch {
          console.log('Resize drawing error');
        }
      }, 300);
    },
    setLoadingVideo(value) {
      this.loadingVideo = value;
      this.loading = false;
      console.log('loading video', value);
    },
    setPlayerErrorMessage(message) {
      this.loadingVideoMessage = message;
    },
    async getStreamStatus() {
      try {
        const res = await axios({
          url: process.env.VUE_APP_API + '/status/session/online',
          method: 'get',
          params: { session_id: this.sessionID },
        });
        if (res.status === 200) {
          this.loading = false;
          this.streamStatus = res.data.status;
          if (this.streamStatus === 'online') {
            clearInterval(this.checkStreamStatus);
          }
        }
      } catch (error) {
        console.log(error);
        // Ignore
      }
      this.getStreamStatusTries += 1;
      if (this.getStreamStatusTries > 16) {
        this.streamStatus = 'online';
        clearInterval(this.checkStreamStatus);
      }
    },
    joinCall() {
      this.$store.commit('setInVideoCall', true);
      window.emitter.emit('joinCall', true);
    },
    leaveCall() {
      this.$store.commit('setInVideoCall', false);
    },
    requestInitalStrokes() {
      this.$socket.sendObj({
        action: 'sendMessage',
        message_type: 'CLIENT_REQUEST',
        message: 'REQUEST_BRUSH_STROKES',
        data: {
          client_token: this.clientToken,
        },
      });
    },
    scaleStrokes(strokes, oldWidth, oldHeight, newWidth, newHeight) {
      let scaleX = newWidth / oldWidth;
      let scaleY = newHeight / oldHeight;

      strokes.forEach((stroke) => {
        stroke.from.x *= scaleX;
        stroke.from.y *= scaleY;
        stroke.coordinates.forEach((coord) => {
          coord.x *= scaleX;
          coord.y *= scaleY;
        });
      });

      return strokes;
    },
    undoDrawStroke() {
      if (this.$refs.VueCanvasDrawing1) this.$refs.VueCanvasDrawing1.undo();
      if (this.$refs.VueCanvasDrawing2) this.$refs.VueCanvasDrawing2.undo();
      this.sendStrokes();
    },
  },
  beforeDestroy() {
    clearInterval(this.checkStreamStatus);
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
}

.card {
  background-color: #091825;
  padding-left: 50px;
  padding-right: 50px;
  display: inline-block;
  text-align: center; /* To keep the text centered */
  border-radius: 20px;
  margin-top: 50px;
}

.card-title {
  font-family: 'Noto Sans Mono', monospace;
  font-weight: 500;
  font-size: 2.2em; /* Make the text bigger */
}

.card-title-smaller {
  font-family: 'Noto Sans Mono', monospace;
  font-weight: 300;
  font-size: 1.4em;
  margin-bottom: 0px;
}

.card-subtitle {
  color: #9ba2a9;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.card-text {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 1.2em; /* Make the text bigger */
}

.input-container,
.button-container {
  margin-bottom: 15px;
}

.form-control {
  width: 300px;
  height: 50px; /* Increase height */
  border: 1px solid grey;
  background-color: transparent;
  border-radius: 12px;
  color: white;
}

.form-control::placeholder {
  color: grey;
  opacity: 1;
  padding-left: 10px; /* Padding around the placeholder */
}

.form-control-password {
  /* ...existing styles... */
  color: white;
  padding-left: 10px; /* Padding around the placeholder */
  -webkit-text-security: disc;
}

input {
  padding-left: 10px; /* Padding around the placeholder */
}

.btn {
  width: 300px;
  height: 50px; /* Increase height */
  background-color: #2e58c7;
  border: none; /* Remove border */
  border-radius: 12px;
  color: white;
  font-family: 'Mono Sans', monospace;
  font-weight: 600;
  font-size: 0.95em;
  letter-spacing: 1.5px;
}

.btn:disabled {
  background-color: #7b7b7b; /* Or any color you prefer */
  cursor: not-allowed;
}

.tooltip-text {
  display: none;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.tooltip-text.active {
  display: block;
}

.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Optional, removes the default iframe border */
}

.toolbar {
  background-color: black;
}

.video-chat img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.participant-name {
  /*right: none !important;*/
}

.call {
  width: 80px;
  margin-top: 20px;
  background-color: green;
  opacity: 0.85;
  padding: 14px 16px 15px;
  border-radius: 12px;
}

.margin-top {
  margin-top: 50px;
  margin-bottom: 50px;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.icon {
  height: 24px;
}

.section {
  padding-left: 45px;
}

.container {
  max-width: 100% !important;
}

.logo-image {
  width: 200px;
}

.reduced-opacity {
  opacity: 0.7;
}

.pause-message-text {
  position: absolute;
  z-index: 2;
  top: 10%;
  font-size: 24px;
  right: 0;
  left: 0;
}

#VueDrawingCanvas {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
}

.padding-80 {
  padding-bottom: 75px;
}

.hide-chat-wrapper {
  display: none;
}

@media (max-width: 991px) {
  .logo-image {
    width: 180px;
  }

  .hide-chat-wrapper {
    display: block;
    flex: 1;
    position: absolute !important;
  }
}

@media (max-width: 800px) {
  .logo-image {
    width: 160px;
  }
}

@media (max-width: 600px) {
  .logo-image {
    width: 140px;
  }
}

@media (max-width: 575px) {
  .chat-section {
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .logo-image {
    width: 130px;
  }
}

@media (max-width: 420px) {
  .logo-image {
    width: 130px;
  }
}

.media-container {
  display: flex;
  box-sizing: border-box;

  &__template {
    height: 100%;
    display: block;
  }
}
</style>
